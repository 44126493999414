@import '../responsive';
@import '../textTypography';
@import '../../variables';

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 144px 16px;
  max-width: 588px;

  @include responsive(mobile-large) {
    padding: 144px 16px;
  }

  @include responsive(tablet) {
    padding: 144px 0;
  }

  .container__image {
    padding: 24px 0;
    border-radius: 4px;

    img {
      display: block;
      max-width: 244px;
      margin: auto;
    }
  }

  .container__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .content__title {
      margin-top: 24px;
      margin-bottom: 16px;

      @include textTypography(Inter, 30px, normal, 600, 38px, 0, center);
    }

    .content__text {
      margin-bottom: 24px;

      @include textTypography(Inter, 16px, normal, 400, 24px, 0, center);
    }

    .content__button {
      background-color: $primary;
      border-radius: 4px;
      padding: 8px 33px;
      color: white;
      display: inline-block;

      @include textTypography(Inter, 16px, normal, 500, 24px, 0, center);
    }
  }
}
